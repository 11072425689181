@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
}

@layer base {
  body {
    @apply bg-dark-primary text-dark-text font-['Inter',sans-serif] antialiased;
  }
}

@layer components {
  .input-style {
    @apply block w-full px-4 py-3 bg-dark-secondary/50 border border-dark-text/10 
           rounded-xl text-dark-text placeholder-dark-text-secondary/50
           focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
           disabled:opacity-50 disabled:cursor-not-allowed
           transition-all duration-200 ease-out relative z-10;
  }

  .input-style:focus {
    @apply transform scale-[1.02];
    box-shadow: 0 0 20px rgba(59, 130, 246, 0.1);
  }

  .btn-primary {
    @apply w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-600
           text-white font-semibold rounded-xl
           hover:from-blue-600 hover:to-indigo-700
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
           disabled:opacity-50 disabled:cursor-not-allowed
           transform hover:scale-[1.02] active:scale-[0.98]
           transition-all duration-200 ease-out;
  }

  .btn-primary::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      115deg,
      transparent 0%,
      transparent 25%,
      rgba(255, 255, 255, 0.1) 45%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.1) 55%,
      transparent 75%,
      transparent 100%
    );
    animation: shine 3s infinite;
  }

  .btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px -10px rgba(59, 130, 246, 0.5);
  }

  .btn-primary:active {
    transform: translateY(0);
  }

  .glass-effect {
    @apply bg-dark-secondary/30 backdrop-blur-lg
           border border-white/5
           shadow-[0_8px_32px_0_rgba(0,0,0,0.5)];
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .floating-card {
    animation: float 6s ease-in-out infinite;
  }

  /* Dashboard specific styles */
  .stat-card {
    @apply relative overflow-hidden;
  }

  .stat-card::before {
    @apply content-[''] absolute inset-0 bg-gradient-to-r from-blue-500/5 to-indigo-500/5
           opacity-0 transition-opacity duration-300;
  }

  .stat-card:hover::before {
    @apply opacity-100;
  }

  .activity-item {
    @apply relative overflow-hidden transition-all duration-300 ease-in-out
           hover:transform hover:scale-[1.02];
  }

  .activity-item::before {
    @apply content-[''] absolute inset-0 bg-gradient-to-r from-blue-500/5 to-indigo-500/5
           opacity-0 transition-opacity duration-300;
  }

  .activity-item:hover::before {
    @apply opacity-100;
  }

  .nav-item {
    @apply relative overflow-hidden transition-all duration-200 ease-in-out;
  }

  .nav-item::before {
    @apply content-[''] absolute inset-0 bg-gradient-to-r from-blue-500/5 to-indigo-500/5
           opacity-0 transition-opacity duration-200;
  }

  .nav-item:hover::before {
    @apply opacity-100;
  }

  .bg-gold {
    background-color: #FFD700;
  }
  
  .text-gold {
    color: #FFD700;
  }

  /* Card Designer Styles */
  .draggable-component {
    position: absolute;
    user-select: none;
    cursor: move;
  }

  .resize-handles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .resize-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #2563eb;
    border: 1px solid white;
    border-radius: 50%;
    pointer-events: auto;
  }

  .resize-handle.top-left {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
  }

  .resize-handle.top-right {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
  }

  .resize-handle.bottom-left {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize;
  }

  .resize-handle.bottom-right {
    bottom: -4px;
    right: -4px;
    cursor: se-resize;
  }
}

@layer utilities {
  .animated-bg {
    @apply bg-dark-primary relative overflow-hidden;
  }

  .animated-bg::before,
  .animated-bg::after {
    @apply content-[''] absolute w-[50vmax] h-[50vmax] -z-[1];
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(59, 130, 246, 0.08) 0%,
      rgba(79, 70, 229, 0.08) 100%
    );
    animation: rotate 20s linear infinite;
  }

  .animated-bg::before {
    @apply -left-[20vmax] -top-[20vmax];
  }

  .animated-bg::after {
    @apply -right-[20vmax] -bottom-[20vmax];
    animation-delay: -10s;
  }

  .animate-slide-in {
    animation: slideIn 0.5s ease-out forwards;
  }

  .animate-slide-out {
    animation: slideOut 0.5s ease-in forwards;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
